<template>
  <q-card
    v-intersection.once="onIntersection"
    class="credova-financing"
    flat
    square
  >
    <q-item v-if="status == 'pending'">
      <q-item-section avatar>
        <q-skeleton
          type="QAvatar"
          size="35px"
        />
      </q-item-section>
      <q-item-section>
        <div class="text-body2">
          <q-skeleton
            type="text"
            width="80%"
            square
          />
        </div>
      </q-item-section>
    </q-item>
    <q-item
      v-else
      :disable="busy"
      style="line-height: 1.4"
      clickable
      @click="onClick()"
    >
      <q-item-section avatar>
        <img
          src="/brands/credova.svg"
          height="35"
          width="35"
        >
      </q-item-section>
      <q-item-section>
        <div class="text-body2">
          <div class="row items-center q-gutter-x-sm">
            <div v-if="data">
              As low as
              <span class="text-weight-medium">
                {{ currency(data.minMonthlyPayment) }}
              </span>/month with <component :is="inlineLogoEl" />
            </div>
            <div v-else>
              Pay over time with <component :is="inlineLogoEl" />
            </div>
          </div>
        </div>
      </q-item-section>
    </q-item>
  </q-card>
</template>

<script lang="ts" setup>
import { h } from 'vue';
import type { LowestMonthlyPayment } from '~/types/credova';

const props = defineProps({
  amount: {
    type: Number,
    required: true,
  },
});

const { $credova } = useNuxtApp();
const { currency } = useFormatting();

const busy = ref(false);

const {
  data,
  status,
  execute,
} = useAsyncData<LowestMonthlyPayment>(
  'credova_lowest_monthly_payment_drawer',
  () => $credova.getLowestMonthlyPayment(props.amount),
  {
    server: false,
    lazy: true,
    immediate: false,
    pick: ['minMonthlyPayment'],
    watch: [() => props.amount],
    getCachedData: getCachedPayload,
  },
);

const onClick = async () => {
  busy.value = true;
  $credova.prequalify(props.amount).finally(() => busy.value = false);
};

const onIntersection = (entry: IntersectionObserverEntry) => {
  if (!entry.isIntersecting) {
    return true;
  }

  if (data.value) {
    return true;
  }

  execute();
  return false;
};

// 4:1 aspect ratio
const inlineLogoEl = h(
  'svg',
  {
    xmlns: 'http://www.w3.org/2000/svg',
    width: 56,
    height: 14,
    viewBox: '0 0 78 20',
  },
  [
    h('path', {
      class: 'logo-path',
      fill: '#2a3e56',
      d: 'M5.652 8.857C2.274 8.857 0 11.087 0 14.266s2.274 5.409 5.652 5.409a5.43 5.43 0 0 0 3.223-.775v-3.111h-.11a3.291 3.291 0 0 1-2.8 1.325 2.746 2.746 0 0 1-3.047-2.848 2.736 2.736 0 0 1 3.047-2.848 3.329 3.329 0 0 1 2.8 1.3h.11V9.63a5.569 5.569 0 0 0-3.223-.773Zm11.127 0a3.118 3.118 0 0 0-3.091 1.832h-.044l-.132-1.612h-2.406v10.377H14v-4.46c0-2.318.795-3.4 2.561-3.4a2.8 2.8 0 0 1 1.634.486h.11V9.254a2.566 2.566 0 0 0-1.526-.397Zm12.96 6.138v-1.17c0-3.047-2.009-4.967-5.232-4.967a5.193 5.193 0 0 0-5.407 5.408c0 3.2 2.495 5.409 6.16 5.409a6.831 6.831 0 0 0 3.86-.975v-2.778h-.11a5.115 5.115 0 0 1-3.621 1.258c-1.788 0-3.069-.773-3.378-2.186Zm-5.144-3.643a2.279 2.279 0 0 1 2.539 1.9h-5.122a2.512 2.512 0 0 1 2.582-1.9ZM42.1 19.454V4h-2.89v5.961h-.044a4.051 4.051 0 0 0-2.98-1.1 5.082 5.082 0 0 0-5.079 5.405 5.082 5.082 0 0 0 5.078 5.409 3.854 3.854 0 0 0 3.378-1.612h.044l.093 1.391Zm-5.429-2.34a2.63 2.63 0 0 1-2.649-2.848 2.63 2.63 0 0 1 2.649-2.848 2.626 2.626 0 0 1 2.629 2.848 2.626 2.626 0 0 1-2.629 2.848ZM49.63 8.857a5.258 5.258 0 0 0-5.563 5.409 5.27 5.27 0 0 0 5.563 5.409 5.264 5.264 0 0 0 5.541-5.409 5.252 5.252 0 0 0-5.541-5.409Zm0 2.561a2.626 2.626 0 0 1 2.627 2.848 2.626 2.626 0 0 1-2.627 2.848 2.63 2.63 0 0 1-2.649-2.848 2.63 2.63 0 0 1 2.649-2.848Zm12.938 8.036 4-10.376h-2.98l-2.433 7.175h-.044L58.7 9.078h-3.087l4.018 10.376Zm15.432 0V9.078h-2.406l-.11 1.347h-.044a3.836 3.836 0 0 0-3.356-1.568 5.082 5.082 0 0 0-5.078 5.409 5.082 5.082 0 0 0 5.078 5.409 3.854 3.854 0 0 0 3.377-1.612h.044l.088 1.391Zm-5.431-2.34a2.63 2.63 0 0 1-2.649-2.848 2.63 2.63 0 0 1 2.649-2.848 2.626 2.626 0 0 1 2.631 2.848 2.626 2.626 0 0 1-2.631 2.848Z',
    }),
  ],
);
</script>

<style lang="scss" scoped>
.credova-financing:deep(.learn-more) {
  color: #243547;
}

.credova-financing:deep(.logo-path) {
  fill: #243547;
}

.body--dark {
  .credova-financing:deep(.learn-more) {
    color: #fff;
  }

  .credova-financing:deep(.logo-path) {
    fill: #fff;
  }
}
</style>
